import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styled, { css, keyframes } from "styled-components";
import { Container, Col, Row } from "react-awesome-styled-grid";
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";
import RichText from "src/atoms/RichText";

import Picture, { FITS } from "src/atoms/Picture";
import { colors, mediaquery } from "src/styles/variables";

import TrackerCategories from "../Tracker/TrackerCategories";
import DonutProgressBar from "src/atoms/DonutProgressBar";
import Share from "../Share";
import Card from "../Card";
import Spacer from "./Spacer";
import transparentize from "polished/lib/color/transparentize";
//import axios from "axios";

// const CLIENT_ID = "094fd3e9b46de17dc6ae693bd32a1d29";
// const REDIRECT_URI = "https://activia-dev.netlify.app";

const TrackerResults = ({
  id,
  title,
  imageDesktop,
  imageMobile,
  categories,
  shareModuleTitle,
  shareModuleTwitterText,
}) => {
  //const { shareYourScoreTitle } = useContext(Settings).translations;
  const [trackerResults, setTrackerResults] = useState(null);
  const [trackerOverallScore, setTrackerOverallScore] = useState(0);
  const [trackerSlug, setTrackerSlug] = useState(null);

  // const [authCode, setAuthCode] = useState("");
  // const [accessToken, setAccessToken] = useState("");
  // const [profileId, setProfileId] = useState("");
  // const [imageURL, setImageURL] = useState("");
  // const [caption, setCaption] = useState("");

  // const authenticate = () => {
  //   window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`;
  // };

  // const handleAuthCodeChange = (event) => {
  //   setAuthCode(event.target.value);
  // };

  // const exchangeCodeForToken = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://api.instagram.com/oauth/access_token",
  //       {
  //         client_id: CLIENT_ID,
  //         client_secret: process.env.CLIENT_SECRET,
  //         grant_type: "authorization_code",
  //         redirect_uri: REDIRECT_URI,
  //         code: authCode,
  //       }
  //     );
  //     setAccessToken(response.data.access_token);
  //   } catch (error) {
  //     console.error("Authentication failed:", error);
  //   }
  // };

  // const getProfileId = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://graph.instagram.com/me?fields=id&access_token=${accessToken}`
  //     );
  //     setProfileId(response.data.id);
  //   } catch (error) {
  //     console.error("Failed to get profile ID:", error);
  //   }
  // };

  // const postImage = async () => {
  //   try {
  //     const response = await axios.post(
  //       `https://graph.facebook.com/${profileId}/media`,
  //       {
  //         image_url: imageURL,
  //         caption: caption,
  //         access_token: accessToken,
  //       }
  //     );
  //     console.log("Image posted successfully:", response.data);
  //   } catch (error) {
  //     console.error("Failed to post image:", error);
  //   }
  // };

  const getTrackerResults = () => {
    const { trackerUrl } = (history && history.state) || {};
    setTrackerSlug(trackerUrl);
    const searchParams = new URLSearchParams(
      window.location.search.split("/")[0]
    );
    const categoryResults = [];
    for (const param of searchParams) {
      categoryResults.push(param);
    }
    setTrackerResults(categoryResults);
    const overallScore = categoryResults.reduce((acc, curr) => {
      return acc + parseInt(curr[1], 10);
    }, 0);
    setTrackerOverallScore(Math.round(overallScore / categoryResults.length));
  };
  const getScoreIndex = () => {
    const scores = title.findIndex((item) => {
      return (
        trackerOverallScore >= item.scoreStart &&
        trackerOverallScore <= item.scoreEnd
      );
    });
    return scores;
  };

  useEffect(() => {
    getTrackerResults();
  }, []);

  return (
    <>
      <Spacer>
        <HeaderCurved fixedHeight={false} backgroundColor="transparent">
          <PictureWrapper
            fit={FITS.cover}
            small={imageMobile}
            large={imageDesktop}
          />
          <ContainerWrapper>
            <ContainerAnimation>
              {title && (
                <NoMarginRowStyled justify="center">
                  <Col md={10} justify="center">
                    {title
                      ?.filter((_, itemIndex) => itemIndex === getScoreIndex())
                      .map((item) => {
                        return (
                          <RichTextWrapper key={`${item.id}`}>
                            <RichText
                              as="div"
                              enableRenderer
                              headColor={colors.white}
                              bodyColor={colors.white}
                              doc={item.adviceTitle.value}
                              alignment={item.adviceTitle.alignment}
                            />
                          </RichTextWrapper>
                        );
                      })}
                  </Col>
                </NoMarginRowStyled>
              )}
              <DonutProgressBarStyled
                strokeColor={colors.activiaGreen}
                percent={trackerOverallScore}
              />
              {shareModuleTitle && (
                <ShareWrapper>
                  <Share
                    title={shareModuleTitle}
                    twitterCopy={shareModuleTwitterText}
                    url={trackerSlug}
                  />
                </ShareWrapper>
              )}
            </ContainerAnimation>
          </ContainerWrapper>
        </HeaderCurved>
      </Spacer>
      <ContainerStyles>
        <NoMarginCardRowStyled>
          <Col md={12} lg={20}>
            <CardsRow>
              {categories?.map((item, index) => {
                if (!item.hideCategoryResults) {
                  return (
                    <CardsCol md={8} key={`${id}_${item.id}`}>
                      <Card
                        __typename={item.__typename}
                        title={item.title}
                        backgroundColor={item.colour}
                        percent={trackerResults?.[index]?.[1]}
                        icon={item.icon}
                        slug={`${item.slug}?${item.id}=${trackerResults?.[index]?.[1]}`}
                        carryState={{
                          trackerUrl: trackerSlug,
                        }}
                      />
                    </CardsCol>
                  );
                }
              })}
            </CardsRow>
          </Col>
        </NoMarginCardRowStyled>
      </ContainerStyles>
      {/* <div>
        <h1>Instagram Image Poster</h1>
        {!accessToken ? (
          <button onClick={authenticate}>Authenticate</button>
        ) : (
          <div>
            <input
              type="text"
              placeholder="Enter Auth Code"
              value={authCode}
              onChange={handleAuthCodeChange}
            />
            <button onClick={exchangeCodeForToken}>Exchange Code</button>
            <button onClick={getProfileId}>Get Profile ID</button>
            <input
              type="text"
              placeholder="Image URL"
              value={imageURL}
              onChange={(e) => setImageURL(e.target.value)}
            />
            <input
              type="text"
              placeholder="Caption"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
            />
            <button onClick={postImage}>Post Image</button>
          </div>
        )}
      </div> */}
    </>
  );
};

TrackerResults.propTypes = {
  id: PropTypes.string,
  title: PropTypes.array,
  imageDesktop: PropTypes.object,
  imageMobile: PropTypes.object,
  image: PropTypes.object,
  categories: PropTypes.arrayOf(TrackerCategories).isRequired,
  shareModuleTitle: PropTypes.string,
  shareModuleTwitterText: PropTypes.string,
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = css`
  animation: 1s ${fadeIn} cubic-bezier(0.47, 0, 0.37, 1) forwards;
`;

const ContainerAnimation = styled.div`
  ${fadeInAnimation};
`;

const NoMarginRowStyled = styled(Row)``;

const NoMarginCardRowStyled = styled(Row)`
  justify-content: center;
`;

const PictureWrapper = styled(Picture)`
  position: absolute !important;
  z-index: -1;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0.7;
    background: ${colors.activiaMediumGreen};
    background: ${transparentize(0.5, colors.black)};
    z-index: 1;
  }
`;

const ContainerWrapper = styled(Container)`
  color: ${colors.white};
  text-align: center;
  padding-top: calc(100px + 1.5rem);
  position: relative;
  z-index: 2;
`;

const ContainerStyles = styled(Container)`
  margin-top: 4rem;
`;

const ShareWrapper = styled.div`
  ul {
    justify-content: center;

    li {
      background-color: ${colors.white};

      &:last-of-type {
        path {
          fill: none;
          stroke: ${colors.activiaGreen};
        }
      }
    }

    path {
      fill: ${colors.activiaGreen};
      stroke: none;
    }

    div {
      color: initial;
    }
  }
`;

const CardsRow = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${mediaquery.sm(css`
    flex-direction: row;
  `)}
`;

const CardsCol = styled.li`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 0 0 auto;
  padding: 0.5rem;

  ${mediaquery.sm(css`
    width: 50%;
  `)}

  ${mediaquery.md(css`
    padding: 1rem;

    &:nth-of-type(odd) {
      justify-content: flex-end;
    }

    &:nth-of-type(even) {
      justify-content: flex-start;
    }
  `)}

  a {
    width: 100%;
    height: unset;
    overflow: auto;
    text-decoration: none;
    box-shadow: none;
    border-radius: 0;

    & > div {
      border-radius: 30px;
    }
  }
`;

const DonutProgressBarStyled = styled(DonutProgressBar)`
  width: 18rem;

  ${mediaquery.md(css`
    width: 28rem;
  `)}

  .donut-text text {
    transform: translate(0, 3px);
  }
`;

const RichTextWrapper = styled.div`
  * {
    color: ${colors.white}!important;
  }
`;

export default TrackerResults;
