import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Spacer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Spacer.propTypes = {
  children: PropTypes.node,
};

const Wrapper = styled.div`
  position: relative;
  top: -2rem;
`;

export default Spacer;
